export const HALLOWEEN_BANNER_ID = 'skipHalloween';
export const HALLOWEEN_END_DATE = '2024-11-30T23:59:59';

export const HALLOWEEN_FINAL_BANNER_ID = 'halloweenFinalBannerClosedAt';

export const getIsHalloween = (): boolean => {
  const halloweenFinalClosedAt = localStorage.getItem(HALLOWEEN_FINAL_BANNER_ID);

  if (halloweenFinalClosedAt) return false;

  const currentDate = new Date();
  const halloweenDate = new Date(HALLOWEEN_END_DATE);
  const isHalloween = currentDate < halloweenDate;

  if (!isHalloween) {
    console.warn('Halloween is over');
  }

  return isHalloween;
};
