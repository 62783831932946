import { PAGE_REF_LINK_BASE, POOL_REF_LINK_BASE } from '@/shared/lib/utils/links';

export const generatePoolRefLink = (userId: number): string => {
  const params = new URLSearchParams({
    utm_source: 'pool_app',
    utm_medium: 'bonus',
    aid: userId.toString(),
  });
  return `${POOL_REF_LINK_BASE}?${params.toString()}`;
};

export const generatePageRefLink = (userId: number): string => {
  const params = new URLSearchParams({
    utm_source: 'pool_app',
    utm_medium: 'bonus',
    aid: userId.toString(),
  });
  return `${PAGE_REF_LINK_BASE}?${params.toString()}`;
};
