import { useModal } from '@/shared/lib/context/modal/useModal';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { BalanceWithdrawModal } from '@/features/balance/balance-withdraw/ui/balance-withdraw-modal/balance-withdraw-modal';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { ModalLanguage } from '@/features/menu/ui/modal-language/modal-language';
import { ModalMiningInfo } from '@/page/cart/ui/v1/modal-mining-info/modal-mining-info';
import { ConfirmWithdrawalModal } from '@/page/withdraw/ui/confirm-withdrawal-modal/confirm-withdrawal-modal';
import { AddressModal } from '@/page/withdraw/ui/address-modal/address-modal';
import { ModalBackground } from '@/features/menu/ui/modal-background/modal-background';
import { ErrorFundsWithdrawalModal } from '@/page/withdraw/ui/error-funds-withdrawal-modal/error-funds-withdrawal-modal';
import { ModalError } from '@/page/withdraw/ui/modal-error/modal-error';
import { CancelWithdrawalModal } from '@/page/withdrawal-details/ui/modals/cancel-withdrawal-modal';
import { SuccessWithdrawalModal } from '@/page/withdrawal-details/ui/modals/success-withdrawal-modal';
import { ModalChangeCurrencyConfirm } from '@/page/switch-balance/ui/modal-change-currency-confirm/modal-change-currency-confirm';
import { ConfirmNcwModal } from '@/page/ncwallet-connect/ui/confirm-ncw-modal/confirm-ncw-modal';
import { RejectNcwModal } from '@/page/ncwallet-connect/ui/reject-ncw-modal/reject-ncw-modal';
import { AddPoolMinerModal } from '@/page/home/ui/add-pool-miner-modal/add-pool-miner-modal';
import { ModalOffer } from '@/page/home/ui/modal-offer/modal-offer';
import { RateUsModal } from '@/features/rate-us';
import { RateUsCompany } from '@/page/home/ui/modal-rate-company/rate-us-company';
import { ModalGoToPayment } from '@/page/cart/ui/v2/modal-go-to-payment';
import { ModalWithdrawSuspended } from '@/page/withdraw/ui/modal-withdraw-suspended/modal-withdraw-suspended';
import { ModalAccountBlocked } from '@/shared/ui/modal-account-blocked/modal-account-blocked';
import { WrongAddressWithdrawalModal } from '@/page/withdraw/ui/wrong-address-withdrawal-modal/wrong-address-withdrawal-modal';
import { ModalHalloweenSkip } from '@/page/home/ui/halloween-banner/modal-halloween-skip/modal-halloween-skip';
import { MegaSaleModal } from '@/page/home/ui/mega-sale-modal/mega-sale-modal';
import { ModalIncreasingHsh } from '@/page/home/ui/modal-increasing-hsh/modal-increasing-hsh';
import { ModalInfoBonusHsh } from '@/page/home/ui/modal-info-bonus-hsh/modal-info-bonus-hsh';
import { ModalPoolMinerActive } from '@/page/home/ui/modal-pool-miner-active/modal-pool-miner-active';
import { BtcConnectModal } from '@/page/ncwallet-connect/ui/btc-connect-modal/btc-connect-modal';
import { useTutorialStorage } from '../../hooks/useTutorialStorage';
import { Modal } from './modal-context';

export const ModalRender = () => {
  const { modal } = useModal();
  const { closeModal } = useModalSet();
  const { pathname } = useLocation();
  const [queue, setQueue] = useState<Modal[]>([]);
  const [currentModal, setCurrentModal] = useState<Modal | null>(null);
  const [intervalRef, setIntervalRef] = useState<number | null>(null);
  const { isOnboardingSeen } = useTutorialStorage();
  const [isRenderingBlocked, setIsRenderingBlocked] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setQueue([]);
      setCurrentModal(null);
    };
  }, []);

  useEffect(() => {
    closeModal();
    setQueue([]);
    setCurrentModal(null);
  }, [pathname, closeModal]);

  useEffect(() => {
    if (!currentModal && isRenderingBlocked) {
      setTimeout(() => setIsRenderingBlocked(false), 5000);
    }
  }, [currentModal, isRenderingBlocked]);

  const checkQueue = useCallback(() => {
    if (isOnboardingSeen() && queue.length > 0) {
      setIsRenderingBlocked(true);
      setCurrentModal(queue[0]);
      setQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [isOnboardingSeen, queue]);

  useEffect(() => {
    intervalRef && clearInterval(intervalRef);
    if (queue.length && !isRenderingBlocked) {
      if (isOnboardingSeen()) {
        checkQueue();
      } else {
        setIntervalRef(setInterval(checkQueue, 5000));
      }
    }
  }, [queue, checkQueue, isRenderingBlocked, isOnboardingSeen]);

  useEffect(() => {
    if (!modal?.name) return;
    setQueue((prevQueue) => {
      if (prevQueue.some((m) => m.name === modal.name)) {
        return prevQueue;
      }
      return [...prevQueue, modal];
    });
  }, [modal]);

  const handleClose = () => {
    closeModal();
    setCurrentModal(null);
  };

  if (!currentModal?.name) {
    return null;
  }

  const Modal = modals[currentModal.name];
  const options = currentModal.options;

  return (
    <>
      <Modal {...options} onClose={handleClose} isOpen={true} />
    </>
  );
};

export const modals = {
  MODAL_MARKET_INFO: BalanceWithdrawModal,
  MODAL_SET_LANGUAGE: ModalLanguage,
  MODAL_SET_BACKGROUND: ModalBackground,
  MODAL_INFO_POOL: ModalMiningInfo,
  MODAL_CONFIRM_WITHDRAWAL: ConfirmWithdrawalModal,
  MODAL_ERROR_FUNDS_WITHDRAWAL: ErrorFundsWithdrawalModal,
  MODAL_ADDRESS_INFO: AddressModal,
  MODAL_ERROR: ModalError,
  MODAL_WITHDRAW_SUSPENDED: ModalWithdrawSuspended,
  MODAL_ACCOUNT_BLOCKED: ModalAccountBlocked,
  MODAL_CANCEL_WITHDRAWAL: CancelWithdrawalModal,
  MODAL_SUCCESS_CANCEL_WITHDRAWAL: SuccessWithdrawalModal,
  MODAL_SWITCH_BALANCE_CONFIRM: ModalChangeCurrencyConfirm,
  MODAL_NC_WALLET_CONFIRM: ConfirmNcwModal,
  MODAL_NC_WALLET_REJECT: RejectNcwModal,
  MODAL_BTC_CONNECT: BtcConnectModal,
  MODAL_PROMO_ADD_POOL_MINER: AddPoolMinerModal,
  MODAL_OFFER: ModalOffer,
  MODAL_RATE_US: RateUsModal,
  MODAL_RATE_COMPANY: RateUsCompany,
  MODAL_GO_TO_PAYMENT: ModalGoToPayment,
  MODAL_ERROR_WITHDRAWAL_ADDRESS: WrongAddressWithdrawalModal,
  MODAL_HALLOWEEN_SKIP: ModalHalloweenSkip,
  MODAL_MEGA_SALE: MegaSaleModal,
  MODAL_INCREASING_HSH: ModalIncreasingHsh,
  MODAl_INFO_BONUS_HSH: ModalInfoBonusHsh,
  MODAL_POOL_MINER_ACTIVE: ModalPoolMinerActive,
};

export type ModalName = keyof typeof modals;
