import React, { useState } from 'react';
import styles from './halloween-banner.module.scss';
import { HALLOWEEN_LINK } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { getIsHalloween, HALLOWEEN_FINAL_BANNER_ID } from '@/page/home/ui/halloween-banner/helpers';
import cn from 'classnames';

export const HalloweenBanner = () => {
  const { t } = useTranslation();
  const [showHalloweenBanner, setShowHalloweenBanner] = useState(() => getIsHalloween());

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(HALLOWEEN_FINAL_BANNER_ID, timestamp.toString());
    setShowHalloweenBanner(false);
  };

  const onLinkClick = () => {
    window.open(HALLOWEEN_LINK, '_blank');
  };

  if (!showHalloweenBanner) {
    return null;
  }

  return (
    <div className="container">
      <div className={styles.root}>
        <span className={styles.close} onClick={onClickCloseBanner}>
          <Icon iconName="close" width="12" height="12" />
        </span>
        <div className={styles.title}>{t('H24.Exchange_banner.Title')}</div>
        <div className={cn(styles.text, styles.text_final)}>{t('H24.Exchange_banner.Descr')}</div>
        <div className={styles.button}>
          <Button color="green" fullWidth onClick={onLinkClick}>
            <Icon iconName="login-white-icon" width="20" height="20" className={styles.icon} />
            {t('H24.Exchange_banner.Btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
