import styles from './share-to-earn-info.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import browserShare from '@/shared/lib/utils/browserShare';
import EarnImg from './../images/earn-img.png';
import { useSelector } from 'react-redux';
import { rootUser } from '@/shared/store/user/user.slice';
import { decode } from 'he';
import { generatePageRefLink, generatePoolRefLink } from '@/shared/lib/utils/generatedRefLinks';
import { useDevice } from '@/shared/lib/hooks/useDevice';

export const ShareToEarnInfo = () => {
  const { t } = useTranslation();
  const { id } = useSelector((store: { user: rootUser }) => store.user);
  const refLink = generatePoolRefLink(id);
  const pageLink = generatePageRefLink(id);
  const { isTelegramMiniApp } = useDevice();
  const onClickShare = async () => {
    const textToShare = t('BB_Hsh.Sharing_text', {
      ref_link: isTelegramMiniApp ? '' : refLink,
      page_link: pageLink,
    });
    try {
      await browserShare({
        url: refLink,
        text: decode(textToShare),
        isTelegramMiniApp,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.image}>
          <img src={EarnImg} alt={t('Earn')} />
        </div>
        <p className={styles.text}>{t('BB_Hsh.BonusProg.Descr')}</p>
      </div>
      <div className={styles.footer}>
        <Button fullWidth color="green" onClick={onClickShare}>
          <Icon iconName="share" width="20" height="20" />
          {t('BB_Hsh.Share_btn')}
        </Button>
      </div>
    </div>
  );
};
